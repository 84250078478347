import { axios } from './bootstrap';

import '../sass/app.scss';

import $ from 'jquery';

import 'bootstrap';

import '@fortawesome/fontawesome-free';

import '@popperjs/core';

import { DateTime, TempusDominus } from '@eonasdan/tempus-dominus';

import moment from 'moment';

import Swal from 'sweetalert2';

import { Fireworks } from 'fireworks-js'

import './theme';

import toastr from 'toastr';

import.meta.glob([
    '../images/**/*',
]);

window.tpl = function (htmlTemplate, arrayParsing, addOn) {
    if (typeof htmlTemplate == 'undefined') {
        console.log('Element not found');
        return;
    }

    if (typeof addOn == 'undefined') addOn = '';
    else addOn = addOn + '.';

    let iTpl;
    for (iTpl in arrayParsing) {
        htmlTemplate = htmlTemplate.replace(new RegExp(`{${addOn}${iTpl}}`, 'g'), arrayParsing[iTpl] ?? '-');
    }
    return htmlTemplate
};

$(function () {
    toastr.options = {
        'closeButton': true,
        'debug': false,
        'newestOnTop': true,
        'progressBar': true,
        'positionClass': 'toast-top-center',
        'preventDuplicates': false,
        'showDuration': '1000',
        'hideDuration': '1000',
        'timeOut': '5000',
        'extendedTimeOut': '1000',
        'showEasing': 'swing',
        'hideEasing': 'linear',
        'showMethod': 'fadeIn',
        'hideMethod': 'fadeOut'
    };
    let $toastlast = [];
    axios.interceptors.request.use((config) => {
        $toastlast[config.url] = toastr['info'](window.rootpage.loading);
        // trigger 'loading=true' event here
        return config;
    }, (error) => {
        // trigger 'loading=false' event here
        return Promise.reject(error);
    });

    axios.interceptors.response.use((response) => {
        toastr.clear($toastlast[response.config.url]);
        // trigger 'loading=false' event here
        return response;
    }, (error) => {
        // trigger 'loading=false' event here
        return Promise.reject(error);
    });

    let dtt = {};
    $('.datepickerc').each(function () {
        let config = $(this).siblings('.datepickerc_config').html();
        let configf = JSON.parse(config);
        dtt[this] = new TempusDominus(this, configf);
        let format = $(this).data('format');
        dtt[this].dates.formatInput = function (date) {
            return moment(date).format(format)
        }
        dtt[this].dates.setValue(new DateTime());
    });
    const screening = $('#screening').data('screening');
    if (screening == 0) {
        Swal.fire($('#screening').data('screening-title'), $('#screening').data('screening-text'), 'info');
    }

    const h = $('.servertime');
    if (h.length > 0) {
        showServerTime(h, h.html())
    }

    function showServerTime(c, d) {
        const e = d.split(':');
        let h = new Date();
        h.setHours(parseInt(e[0], 10));
        h.setMinutes(parseInt(e[1], 10));
        h.setSeconds(parseInt(e[2], 10));
        let f = new Date().getTime() - h.getTime();
        let g = {
            displayTime: function () {
                let a = new Date(new Date().getTime() - f);
                c.text([g.leadZeros(a.getHours(), 2), g.leadZeros(a.getMinutes(), 2), g.leadZeros(a.getSeconds(), 2)].join(':'));
                setTimeout(g.displayTime, 1000)
            },
            leadZeros: function (a, b) {
                while (String(a).length < b) {
                    a = '0' + a
                }
                return a
            }
        };
        g.displayTime()
    }

    if (performance.navigation.type == 2) {
        location.reload(true);
    }

    const isBirthday = $('#birthday').data('birthday');
    if (isBirthday == 1) {
        const birthday = document.querySelector('#birthday');
        birthday.style.display = 'block';
        const fireworks = new Fireworks(birthday, { /* options */ });
        fireworks.start();
        setTimeout(() => {
            fireworks.stop();
            birthday.style.display = 'none';
        }, 5000);
    }

    if ($('.toggle-sidebar-btn').length > 0) {
        $(document).click(function (e) {
            if ($('body').hasClass('toggle-sidebar') &&
                e.target.closest('.sidebar') == null &&
                !$(e.target).hasClass('toggle-sidebar-btn')) {
                $('body').removeClass('toggle-sidebar');
            }
        });
    }
})

export {
    $,
    Swal,
    axios
}
